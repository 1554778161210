.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.navbar {
  padding: 0px;
  height: 70px;
}
.trcollapsebody td {
  border-bottom: none !important;
}
.collapsebody td {
  border-bottom: none !important;
}
.defaultIconColor {
  color: #f16856 !important;
}
.filterTable header {
  display: none;
}
.subnawbar {
  box-shadow: none;
}
.newModalFluid {
  width: 100% !important;
  min-width: 100%;
}
.noWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nav-tabs .nav-link {
  color: #5a5a5a !important;
}
.subnawbar {
  font-size: 14px;
  border-bottom: #ddd 0.3px solid;
}
.navbar-dark .navbar-brand {
}
.mainList a,
.mainList p,
.mainList i,
.mainList strong {
  color: #444;
}
.mainList:hover a,
.mainList:hover p,
.mainList:hover i,
.mainList:hover strong {
  color: #111;
}
@media (max-width: 768px) {
  #mainNavbarCollapse {
    background: #fff;
  }
}
.subnawbar .nav-link {
  color: #1e1e1e;
  font-weight: 400;
}
.subnawbar .nav-link.active {
  background-color: transparent;
  border-bottom: #03a9f4 solid 0.3px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MuiAppBar-colorInherit {
  z-index: 0;
}
.navbar-light .navbar-brand {
  font-size: 16px;
  line-height: 2.5rem;
  background-color: #fff;
  padding: 5px 1rem;
  color: #f3f3f3 !important;
}
.card-body {
  background: transparent !important;
}
.card-text {
  background-color: white;
}
.btn-warning {
  color: #282c34;
}
.navbar-light .navbar-brand:hover {
  background-color: #fff;
}

@media (min-width: 600px) {
  .navbar.scrolling-navbar {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
.navbar-collapse {
  font-size: 14px;
}
.nav-link.active {
  background-color: #fff;
  color: #f16856 !important;
}
.MuiTableCell-root {
  padding: 4px !important;
  font-size: 11px !important;
}
.two_title {
  color: #f16856 !important;
  font-weight: 500 !important;

  background: #fafafa !important;
}
.MuiTableCell-head {
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.rdt_TableHeader {
}
.dateGlobal {
  position: absolute;
  z-index: 89;
}
.breadcrumb-item {
  display: inherit;
}
.datePickerCustom {
}
.one_title {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f05742+0,efa9a0+100 */

  background: #f9f9f9 !important;
}
.App-link {
  color: #61dafb;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"); /* Using a url */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons"); /* Using a url */

.settingsBtn {
  box-shadow: none;
}
.settingsBtn:hover,
.settingsBtn:focus {
  box-shadow: none;
}
.btn-createbtn {
  box-shadow: none;
  background-color: #25a9f4;
  color: white;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}
.btn-createbtn:hover {
  box-shadow: none;
  color: white;
  background-color: #1d7fb8;
}
.modal-body .grey-text {
  margin-bottom: 0.3rem;
  font-size: 14px;
}
.filterIcon {
  cursor: pointer;
}
.filterIcon:hover {
  color: #f16856;
}
.modal-body .form-control {
  padding: 0.25rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 0.8rem;
}
.card-body .form-control {
  padding: 0.25rem 0.75rem;
  height: calc(1.5em + 0.75rem);
  font-size: 0.8rem;
}
.card-body label {
  margin-bottom: 0.25rem;
}

.newcreateive th {
  padding: 3px !important;
  background: #dadada;
}
.altable {
  width: 70%;
}
.ad_type {
  width: 47%;
}

.cTEQON,
.doBktq {
  max-height: 35px;
  min-height: 35px !important;
}
.campaingTable .input-group-text,
.campaingTable {
  padding: 0.1rem 0.5rem;
  font-size: 12px;
}
.altable .css-yk16xz-control,
.altable .css-1pahdxg-control {
  background-color: #e9ecef;
  border-radius: 0px 4px 4px 0px !important;
}
.mainFilter {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.allreporttable {
  display: inline-flex;
}
.allreporttable .select__option {
  line-height: 1.5rem;
}
.allreporttable .select__single-value {
  overflow: visible;
}
.allreporttable .css-yk16xz-control,
.allreporttable .css-1pahdxg-control {
  border-radius: 6px 0px 0px 6px !important;
  min-height: 36px !important;
  min-width: 100px;
}
.campaingListGroup .list-group-item + .list-group-item.active {
  margin-top: 0px;
  border-top-width: 0px;
}
.campaingListGroup li {
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
}
.campaingListGroup .active {
  z-index: 0;
  background-color: transparent;
  color: #f16856;
}
.campaingListGroup {
  overflow-x: auto;
  max-height: calc(100% - 50px);
}
#allBr br {
  display: none;
}
.campId {
  font-size: 10px;
  color: #999 !important;
}
.homeCardsText {
  font-size: 1rem !important;
  margin-bottom: 0.2rem !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #111111 !important;
}
.rs-checkbox-checker {
  min-height: 26px !important;
}
.without-children .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label::before {
  min-height: 26px !important;
}
.rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  top: 5px !important;
}
.without-children .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label {
  padding: 2px 12px 2px 34px;
}
.rs-picker-toolbar-right-btn-ok {
  background-color: #ff7043 !important;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #ffffff;
  border-color: #ff7043 !important;
  background-color: #ff7043 !important;
}
.rs-calendar-table-cell-in-range::before {
  background-color: rgba(204, 233, 255, 0.5) !important;
}
.rs-picker-daterange-menu {
  right: 12px !important;
  left: auto !important;
}
.breadcrumb-item a {
  color: #5a5a5a;
}
.breadcrumb-item .close {
  font-size: 16px;
  text-align: center;
  padding-top: 3px;
  padding-left: 3px;
  color: red;
}
.breadcrumb-item a:hover {
  color: #ff7043;
}
.MuiTableCell-body a {
  font-weight: 500;
  color: #5a5a5a !important;
}
.trbody .MuiTableCell-body {
  color: #5a5a5a !important;
}
.btn-group {
}
.chartsNew h6 {
  margin-top: 1rem;
}
.domainList:disabled,
.domainList[readonly] {
  background-color: #fff !important;
  border: none;
}
.blacklist_div {
  display: none;
  padding: 2px 5px !important;
}
.blacklist_div.active {
  display: block;
}
.btn-defaultBackBtn {
  border: none !important;
  padding: 5px 15px;
  background-color: rgb(242, 104, 85) !important;
  color: white !important;
  box-shadow: none !important;
}
.btn-defaultBtn {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 5px 15px !important;
  box-shadow: none !important;
}
.btn-defaultBtn:hover {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}
.btn-excelBTN {
  float: right;
  padding: 3px 15px !important;
  border: none !important;
  font-size: 1rem !important;
  box-shadow: none !important;
  color: rgb(242, 104, 85) !important;
}

.defaultBtn-outline {
  border: none;
  padding: 3px !important;
  box-shadow: none !important;
}
.btn-defaultExBtn {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 3px 8px;
  box-shadow: none !important;
}
.btn-defaultExBtn:hover {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}
.defaultExBtn-outline {
  border: none;
  padding: 3px !important;
  box-shadow: none !important;
}
.btn-viewBtn {
  color: #9e9e9e !important;
  border: none;
  padding: 3px !important;
  box-shadow: none !important;
}
.btn-viewBtn:hover {
  border: none;
  padding: 3px !important;
  color: #1e1e1e !important;
}
.viewBtn-outline {
  border: 1px solid rgba(255, 255, 255, 0.23);
  padding: 5px 15px;
  box-shadow: none !important;
}

.dropReport {
  float: left;
}
.dropReport > .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content,
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:hover,
.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:focus {
  color: #ff7043 !important;
}
.dropReport .rs-btn-ghost {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.rs-btn-ghost {
  text-decoration: none;
  color: #5a5a5a !important;
  border: 1px solid #dadada !important;
}
.rs-btn-active {
  text-decoration: none;
  color: #ff7043 !important;
}
.rs-btn-ghost:hover {
  text-decoration: none;
  color: #ff7043 !important;
}

/* Bu kısım Loader */
.lds-ripple {
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f26955;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
/* Bu kısım Loader */
