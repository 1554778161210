body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  body {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
  }
  .ipl-progress-indicator.available {
    opacity: 0;
  }
  .ipl-progress-indicator {
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 1;
    pointer-events: none;
    -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
    -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
    transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
    z-index: 99;
  }
  .insp-logo-frame svg{
    margin-left:auto;
    margin-right: auto;
  }
  .insp-logo-frame {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-animation: fadein 436ms;
    -moz-animation: fadein 436ms;
    animation: fadein 436ms;
    height: 98%;
  }
  .insp-logo-frame-img {
    width: 112px;
    height: 112px;
    -webkit-align-self: center;
    -moz-align-self: center;
    align-self: center;
    border-radius: 50%;
  }
  .ipl-progress-indicator-head {
    background-color: #ffe7bc;
    height: 4px;
    overflow: hidden;
    position: relative;
  }
  .ipl-progress-indicator .first-indicator,
  .ipl-progress-indicator .second-indicator {
    background-color: #ffaf64;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    transform: scaleX(0);
  }
  .ipl-progress-indicator .first-indicator {
    -webkit-animation: first-indicator 2s linear infinite;
    -moz-animation: first-indicator 2s linear infinite;
    animation: first-indicator 2s linear infinite;
  }
  .ipl-progress-indicator .second-indicator {
    -webkit-animation: second-indicator 2s linear infinite;
    -moz-animation: second-indicator 2s linear infinite;
    animation: second-indicator 2s linear infinite;
  }
  .ipl-progress-indicator .insp-logo {
    animation: App-logo-spin infinite 20s linear;
    border-radius: 50%;
    -webkit-align-self: center;
    -moz-align-self: center;
    align-self: center;
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes first-indicator {
    0% {
      transform: translate(0) scaleX(0);
    }
    25% {
      transform: translate(0) scaleX(0.5);
    }
    50% {
      transform: translate(25%) scaleX(0.75);
    }
    75% {
      transform: translate(100%) scaleX(0);
    }
    100% {
      transform: translate(100%) scaleX(0);
    }
  }
  @keyframes second-indicator {
    0% {
      transform: translate(0) scaleX(0);
    }
    60% {
      transform: translate(0) scaleX(0);
    }
    80% {
      transform: translate(0) scaleX(0.6);
    }
    100% {
      transform: translate(100%) scaleX(0.1);
    }
  }